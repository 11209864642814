import './ConnectorTable.css';

import React, {useState} from 'react';

import { CircularProgress } from '@mui/material';
import DefaultModal from 'components/DefaultModal/DefaultModal';
import PropTypes from 'prop-types';

const options = {
    chart: {
        height: 700,
        type: 'line',
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        width: 2,
        dashArray: 0,
    },
    title: {
        text: 'Projected Loss Graph',
        align: 'left'
    },
    grid: {
        row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: {
        title: {
            text: 'Date'
        }
    },
    yaxis: {
        title: {
            text: 'Cumulative Projected Loss (in CHF)'
        },
    },
};


const UserTable = ({ users }) => {
    const [addUserModal, setAddUserModal] = useState(false);
    const openAddUserModal = () => setAddUserModal(true);
    const closeAddUserModal = () => {
        setAddUserModal(false);
    };

    return (
        <>
            <div className='connecter-box'>
                <p>Users</p>
            </div>
            <div className='col-lg-12 reports-content'>
                <div className='row reports-content-row'>
                    {users.length ? (
                        <div className='reports-content-box' style={{ textAlign: 'left' }}>
                            <table className="table table-hover mobile-none">
                                <thead>
                                    <tr>
                                        <th scope="col" className='table-heading'>User Name</th>
                                        <th scope="col" className='table-heading'>Email Address
                                        </th>
                                        <th scope="col" className='table-heading'>{'Company Name'}</th>
                                        <th scope='col' className='table-heading'>
                                            User Role
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users?.map((plant ) => (
                                        <tr
                                            // style={{textAlign: 'center'}}
                                            key={plant.contact_name}
                                        >
                                            <td>
                                                {plant.contact_name}
                                            </td>
                                            <td>
                                                {plant.contact_email}
                                            </td>
                                            <td>
                                                {plant.company_name ? plant.company_name : "SmartHelio"}
                                            </td>
                                            <td>
                                                {plant.role ? plant.role : "Admin"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
            <div className='add-device-btn'>
                <button type="button" onClick={openAddUserModal}>ADD USER</button>
            </div>
            <div className='connecter-footer'>
                <p>Smarthelio 2022</p>
            </div>
            <DefaultModal
                show={addUserModal}
                onHide={() => closeAddUserModal(false)}
            />
        </>
    )
}

// UserTable.propTypes = {
//     plants: PropTypes.arrayOf(Object).isRequired,
//     mainStats: PropTypes.instanceOf(Object).isRequired,
// };

export default UserTable;
