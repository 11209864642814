import callApi, { LambdaCallApi } from "../../util/apiCaller";
import { errorMsg, successMsg } from "util/toast";

import {APP_TOKEN} from "constants/enums/appEnums";
import { AUTHENTICATED } from "constants/enums/authEnums";
import jwt_decode from "jwt-decode";
import  plantMapping  from "util/plantUtil";

export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_USER = 'SET_USER';
export const SET_PLANTS = 'SET_PLANTS';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';
export const SET_IS_LOGIN_LOADING = 'SET_IS_LOGIN_LOADING';

export const setAuthState = authState => ({
    type: SET_AUTH_STATE,
    authState,
});

export const setIsLoginLoading = isLoginLoading => ({
    type: SET_IS_LOGIN_LOADING,
    isLoginLoading,
});

export const setIsLoading = isLoading => ({
    type: SET_IS_LOADING,
    isLoading,
});

export const setCompany = company => ({
    type: SET_COMPANY,
    company,
});

export const setPlants = plants => ({
    type: SET_PLANTS,
    plants,
});

export const setUser = user => ({
    type: SET_USER,
    user,
});

export function getPlants(filters) {
    return async dispatch => {
        try {
            const res = await LambdaCallApi(`plant/filter?${filters}`, 'get', null);
            if (res && res.statusCode === 200) {
                res.body.records.map(record => {
                    const newRecord = {...record}
                    newRecord.plant_id_dummy = plantMapping(newRecord.plant_id);
                    newRecord.plant_address = '';
                    newRecord.label = newRecord.plant_id_fk;
                    newRecord.value = newRecord.plant_id.toString();
                    return newRecord;
                })
                dispatch(setPlants(res.body.records));
            }

        } catch (err) {
            // do something here like show error toast or loading false
        }
    }
}

export function getCompanyDetails(filters) {
    return async dispatch => {
        try {
            const res = await LambdaCallApi(`company/filter?${filters}`, 'get', null);
            if (res && res.statusCode === 200) {
                dispatch(setCompany(res.body.records[0] ?? null));
            }
        } catch (err) {
            // do something here like show error toast or loading false
        }
    }
}

export function loginUser(creds) {
    return async dispatch => {
        try {
            dispatch(setIsLoginLoading(true));
            const res = await callApi('auth/user/login', 'post', creds);

            dispatch(setIsLoginLoading(false));
            if (res && res.status === 'Success') {
                const { token } = res.data;
                window.localStorage.setItem(APP_TOKEN, token);
                const tempUser = jwt_decode(token);
                dispatch(setUser(tempUser));
                dispatch(setAuthState(AUTHENTICATED));
                successMsg("Welcome to SmartHelio");
            } else {
                errorMsg("Credential are not valid....!");
            }

        } catch (err) {
            errorMsg("Something went wrong..!");
            dispatch(setIsLoginLoading(false));
        }
    }
}
