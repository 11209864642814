import './ConnectorTable.css';

import React, {useState} from 'react';

import { CircularProgress } from '@mui/material';
import DefaultModal from 'components/DefaultModal/DefaultModal';
import PropTypes from 'prop-types';

const options = {
    chart: {
        height: 700,
        type: 'line',
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        curve: 'smooth',
        lineCap: 'butt',
        width: 2,
        dashArray: 0,
    },
    title: {
        text: 'Projected Loss Graph',
        align: 'left'
    },
    grid: {
        row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: {
        title: {
            text: 'Date'
        }
    },
    yaxis: {
        title: {
            text: 'Cumulative Projected Loss (in CHF)'
        },
    },
};

const DeviceTable = ({ connectorDevices }) => {
    const [addDeviceModal, setAddDeviceModal] = useState(false);
    const openAddDeviceModal = () => setAddDeviceModal(true);
    const closeAddDeviceModal = () => {
        setAddDeviceModal(false);
    };

    return (
        <>
            <div className='connecter-box'>
                <p>Devices</p>
            </div>
            <div className='col-lg-12 reports-content'>
                <div className='row reports-content-row'>
                    {connectorDevices.length ? (
                        <div className='reports-content-box' style={{ textAlign: 'left' }}>
                            <table className="table table-hover mobile-none">
                                <thead>
                                    <tr>
                                        <th scope="col" className='table-heading'>Clients</th>
                                        <th scope="col" className='table-heading'>Site Address
                                        </th>
                                        <th scope="col" className='table-heading'>{'Device ID'}</th>
                                        <th scope='col' className='table-heading'>
                                            Device Name
                                        </th>
                                        <th scope='col' className='table-heading'>
                                            Label
                                        </th>
                                        <th scope='col' className='table-heading'>
                                            Confidence Level
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {connectorDevices?.map((plant) => (
                                        <tr
                                            // style={{textAlign: 'center'}}
                                            key={plant.connector_name}
                                        >

                                            <td>
                                                {plant.client}
                                            </td>
                                            <td>
                                                {plant.site_address}
                                            </td>
                                            <td>
                                                {plant.device_id}
                                            </td>
                                            <td>
                                                {plant.connector_name}
                                            </td>
                                            <td>
                                                {plant.fault}
                                            </td>
                                            <td>
                                                {plant.confidence_interval}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </div>
            <div className='add-device-btn'>
                <button type="button" onClick={openAddDeviceModal}>ADD DEVICE</button>
            </div>
            <div className='connecter-footer'>
                <p>Smarthelio 2022</p>
            </div>
            <DefaultModal
             show={addDeviceModal}
             onHide={() => closeAddDeviceModal(false)}
            />

            

        </>
    )
}

// DeviceTable.propTypes = {
//     plants: PropTypes.arrayOf(Object).isRequired,
//     mainStats: PropTypes.instanceOf(Object).isRequired,
// };

export default DeviceTable;
