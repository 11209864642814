const plantMapping = id => {
    if (id === 5) {
        return '3';
    }
    if (id === 6) {
        return '5';
    }
    if (id === 27) {
        return '1';
    }
    return '0';
};

export default plantMapping ;
