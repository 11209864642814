import './upload.css';

import Brand from '../../assets/brand-img.png'
import LoginImg from '../../assets/login-bg-1.png'
import React from 'react';
import {useCSVReader} from "react-papaparse";

// import callApi from "../../util/apiCaller";



const UploadPage = () => {

    const {CSVReader} = useCSVReader();

    // const [user, setUserData] = useState({
    //     email: '',
    //     password: ''
    // });

    // const [errors, setErrors] = useState({});

    // const handleLoginAPI = (creds, next) => {
    //     callApi(`auth/user/login`, 'post', {
    //         user: creds
    //     }).then(res => {
    //         return next(res);
    //     })
    // };

    const downloadObjectAsJson = (exportObj, exportName) => {
        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(exportObj))}`;
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download",`${exportName}.json`);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    const handleOnFileLoad = (data, file) => {
        const csv_data = data;
        downloadObjectAsJson(csv_data, file.name);
    };

    return (
        
            <section className='login-section'>
                <div className="img-box">
                    <img src={LoginImg} alt="LoginImg"/>
                </div>
                <div className="content-box">
                    <div className="form-box">
                        <img src={Brand} className='brand-img' alt=""/>
                        <h2>Upload</h2>
                        <CSVReader
                            onUploadAccepted={handleOnFileLoad}
                        >
                            {({
                                  getRootProps,
                                  acceptedFile,
                                  ProgressBar,
                                  getRemoveFileProps,
                              }) => (
                               
                                    <div>
                                        <button type='button' {...getRootProps()} >
                                            Browse file
                                        </button>
                                        <div>
                                            {acceptedFile && acceptedFile.name}
                                        </div>
                                        <button {...getRemoveFileProps()} type="button">
                                            Remove
                                        </button>
                                    </div>
                                
                            )}
                        </CSVReader>
                    </div>
                </div>
            </section>
       
    )
}

export default UploadPage;
