import { AUTHENTICATED, AUTHENTICATNG, NOT_AUTHENTICATED } from "constants/enums/authEnums";
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import ActionDashboard from 'pages/ActionDashboard/ActionDashboard';
import ActionSensorPage from 'pages/ActionSensorPage/ActionSensorPage';
import ConnectorsListPage from '../pages/ConnectorsListPage/ConnectorsListPage';
import DeviceInfoPage from '../pages/DeviceInfoPage/DeviceInfoPage';
import DevicesListPage from '../pages/DevicesListPage/DevicesListPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import FullPageLoader from '../components/FullPageLoader/FullPageLoader';
import LoginPage from '../pages/LoginPage/LoginPage'
import NavbarSidebarGlobal from '../components/NavbarSidebarGLobal/NavbarSidebarGlobal';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import React from 'react';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import UploadPage from '../pages/UploadPage/UploadPage';
import UserListPage from '../pages/UserListPage/UserListPage';
import SensorPage from 'pages/SensorPage/SensorPage';
import { getAuthenticatedState } from "store/App/AppReducer";
import { useSelector } from 'react-redux';

// pages










// other components and utils
//  {/*<Route path='/action-sensor' element={<ActionSensorPage />} />*/}
//                 {/*<Route path='sensor' element={<SensorPage />} />*/}
//
const AuthenticatedRoutes = () => {
    return (
        <Routes>
            <Route path={'/'} element={<NavbarSidebarGlobal><Outlet /></NavbarSidebarGlobal>}>
                <Route index element={<ConnectorsListPage />} />
                <Route path='connectors' element={<ConnectorsListPage />} />
                <Route path='users' element={<UserListPage />} />
                <Route path='devices' element={<DevicesListPage />} />
                <Route path='action-dashboard' element={<ActionDashboard />} />
                <Route path={"device"}>
                    <Route path=':id/view' element={<DeviceInfoPage />} />
                </Route>
                {/* <Route index element={<UserListPage />} />
                <Route path='portfolioReports' element={<UserListPage />} />
                <Route path='microservice' element={<MicroServicePage />} />
                <Route path='financial-projections' element={<LoginPage />} />
                <Route path='forecast' element={<LoginPage />} />
                <Route path='map-view' element={<MapViewPage />} />
                <Route path={"plant"}>
                    <Route path='microservice' element={<MicroServicePage />} />
                    <Route path=':id/performance-report' element={<DeviceInfoPage />} />
                    <Route path=':id/cleaning-schedule' element={<CleaningSchedulePage />} />
                    <Route path=':id/shading-analysis' element={<LoginPage />} />
                    <Route path=':id/loss-projection' element={<LoginPage />} />
                    <Route path=':id/tickets' element={<TicketsPage />} />
                    <Route path=':id/comparative-analysis' element={<ComprativeAnalysisPage />} />
                    <Route path=':id/production-forecast' element={<LoginPage />} />
                </Route>
                <Route path='/login' element={<Navigate to="/" />} />
                <Route path="*" element={<NotFoundPage />} /> */}
            </Route>
            <Route path='/upload' element={<UploadPage />} />
           
            <Route path='/reset-password' element={<ResetPasswordPage />} />
            <Route path='/login' element={<Navigate to="/" />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}

const NotAuthenticatedRoutes = () => {
    return (
        <Routes>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/forgot-password' element={<ForgotPasswordPage />} />
            <Route path='/reset-password' element={<ResetPasswordPage />} />
            <Route path='/' element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
}

function AppRoutes() {
    const authState = useSelector(state => getAuthenticatedState(state));

    const getRoutes = () => {
        switch(authState) {
            case AUTHENTICATED:
                return <AuthenticatedRoutes />;
            case NOT_AUTHENTICATED:
                return <NotAuthenticatedRoutes />;
            case AUTHENTICATNG:
                return <FullPageLoader />;
            default:
                return <FullPageLoader />;
        }
    }

    return (
        <>
            {getRoutes()}
        </>
    )
}

export default AppRoutes;
