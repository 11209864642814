import './header.css';

import React, {useState} from 'react';
import {setAuthState, setCompany, setPlants, setUser} from "store/App/AppActions";
import { useDispatch, useSelector } from "react-redux";

import {APP_TOKEN} from "constants/enums/appEnums";
import ArrowBottom from '../../assets/arrowBottom.svg';
import BrandImg from '../../assets/brand-img.png';
import Info from '../../assets/info.svg';
import {Link} from 'react-router-dom';
import {NOT_AUTHENTICATED} from "constants/enums/authEnums";
import ReactTooltip from "react-tooltip";
import StaubliLogo from '../../assets/staubliLogo.svg';
import { getUser } from "store/App/AppReducer";
import moment from 'moment';

const Header = () => {
    const [date] = useState(moment().format('DD/MM/YYYY'));
    const dispatch = useDispatch();
    const user = useSelector(state => getUser(state));

    const logOut = () => {
        dispatch(setPlants([]));
        dispatch(setCompany(null));
        dispatch(setUser(null));
        dispatch(setAuthState(NOT_AUTHENTICATED));
        window.localStorage.removeItem(APP_TOKEN);
    }

    const getShortName = (str) => {
        if (str) {
            const strCopy = typeof str !== "string" ? str.toString() : str;
            const name = (strCopy)
            .match(/(^\S\S?|\b\S)?/g)
            .join("")
            .match(/(^\S|\S$)?/g)
            .join("")
            .toUpperCase();
            return name;
        }
        return str;
    };

    return (
       
            <nav className='navbar navbar-expand-lg navbar-dark sticky-header'>
                <div className='navbar-container'>
                    <div className='nav-left-content'>
                        <Link exact to='/'>
                            <img src={StaubliLogo} alt="LogoImg" className='navbar-logo' style={{marginRight: "15px"}}/>
                            <img src={BrandImg} alt="LogoImg" className='navbar-logo'/>
                        </Link>
                    </div>
                    <div className='nav-right-content dropdown'>
                        <span className='profile-dp'>
                            <div className="personal-logo">{getShortName(user.contact_name)}</div>
                            <img src={ArrowBottom} alt="LogoImg" />
                        </span>
                        <div className="dropdown-content">
                            <div className="dropdown-item">{user.contact_name}</div>
                            <div className="dropdown-item" style={{display: "flex", justifyContent: "space-between"}}>
                                <Link to="/users" style={{textDecoration: "none", color: "#000"}}>Add/Edit User</Link>
                                <img data-tip data-for="info-user" src={Info} alt="info"/>
                                <ReactTooltip
                                  className="tooltip"
                                  id="info-user"
                                  place="bottom"
                                  effect="solid"
                                >
                                    Only admin
                                </ReactTooltip>
                            </div>
                            <div className="dropdown-item" style={{display: "flex", justifyContent: "space-between"}}>
                                <Link to="/devices" style={{textDecoration: "none", color: "#000"}}>Add/Device</Link>
                                <img data-tip data-for="info-device" src={Info} alt="info"/>
                                <ReactTooltip
                                  className="tooltip"
                                  id="info-device"
                                  place="bottom"
                                >
                                    Only admin
                                </ReactTooltip>
                            </div>
                            <button type="button" className="dropdown-item" onClick={() => logOut()}>Logout</button>
                        </div>
                    </div>
                </div>
            </nav>
    )
}

export default Header;
