import ConnectorTable from '../../components/ConnectorTable/ConnectorTable';
import {Helmet} from "react-helmet";
import React, { useState } from 'react';
import connectorDevices from 'content/connectorDevices.json';

const faultMapper = {
    'Normal' : 1,
    'Crossmated' : 3,
    'Bad Crimp' : 4,
    'Field aged' : 2,
};

const ConnectorsListPage = () => {

    const [selectedTab, setSelectedTab] = useState("1");

    return (
        
            <div className='row portfolio-row' style={{padding: "2rem"}}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Portfolio | SmartHelio</title>
                </Helmet>
                {connectorDevices.length > 0 && (
                    <ConnectorTable
                        faultMapper={faultMapper}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        connectorDevices={connectorDevices}
                    />
                )}
            </div>

        
    )
}

export default ConnectorsListPage;
