import Box from "konnect/Box"
import React from "react";

const ConnectorMiddleHeader = ({setSelectedTab}) => {
    const itemData = [
        {name: "Normal(no fault)"},
        {name: "fault type 1"},
        {name: "fault type 2"},
        {name: "fault type 3"},
        {name: "fault type 4"},
    ];

    let listData = itemData.map((item, index) => {
        return <div className="itemmiddleHeader">{item.name}</div>;
    });

    return (
        <div className="middleContainer">
            <Box display="flex" gridGap="6px">
                <div onClick={() => setSelectedTab('1')} className="sub-header-value1" style={{background:'#008040' ,width: "30%",borderRadius:'4px 0px 0px 6px',paddingTop:"26px",fontWeight:'600', height:'76px'}}>Normal (
                    no fault )
                </div>
                <div
                    style={{background: 'rgb(65 197 53 / 55%)', width: "18%",paddingTop:"26px",fontWeight:'600', height:'76px'}}
                    onClick={() => setSelectedTab('2')} className="sub-header-value"
                    >Field aged
                </div>
                <div onClick={() => setSelectedTab('3')} className="sub-header-value"
                     style={{background: 'rgb(232 47 9 / 45%)', width: "30%",paddingTop:"26px",fontWeight:'600', height:'76px', }}
                >Crossmated
                </div>
                <div
                    style={{background: 'rgb(212 35 21 / 68%)', width: "20%", paddingTop:"26px",fontWeight:'600', height:'76px', borderRadius:'0px 5px 6px 0px'}}
                    onClick={() => setSelectedTab('4')} className="sub-header-value">Bad Crimp
                </div>
            </Box>
        </div>
    );
};

export default ConnectorMiddleHeader;


