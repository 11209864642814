import './login.css';

import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getAuthenticatedState, getIsLoginLoading} from "store/App/AppReducer";

import { CircularProgress } from '@material-ui/core';
import {Helmet} from "react-helmet";
import {Link} from 'react-router-dom'
import LoginImg from '../../assets/login-img.png';
import {loginUser} from "store/App/AppActions";

const Login = () => {
    const [user, setUserData] = useState({
        email: '',
        password: ''
    });
    const dispatch = useDispatch();
    const isLoginLoading = useSelector(state => getIsLoginLoading(state));
    const [errors, setErrors] = useState({});

   

    const handleChangeUser = (keyName, keyValue) => {
        const update = {...user};
        update[keyName] = keyValue;
        setUserData(update);
    };

    const handleValidation = (client) => {
        let isValid = true;
        const newErrors = {};
        if (!client.email) {
            isValid = false;
            newErrors.email = 'Email is required.';
        }
        if (!client.password) {
            isValid = false;
            newErrors.password = 'Password is required.';
        }
        return {
            isValid,
            errors: newErrors
        }
    };

     const handleLoginAPI = (creds) =>{
         const USER = {
             user: creds
         };

         dispatch(loginUser(USER))
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const check = handleValidation(user);
        if (check.isValid) {
            handleLoginAPI(user)
        } else {
            setErrors(check.errors);
        }
    };

    return (
        <>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Login | SmartHelio</title>
            </Helmet>
            <section className='login-section'>
                <div className="img-box">
                    <img style={{height:"100%"}} src={LoginImg} alt="LoginImg"/>
                </div>
                <div className="content-box">
                    <div className="form-box">
                        {/* <img src={Brand} className='brand-img' alt=""/> */}
                        <h2>Login</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="input-box">
                                <span>Email*</span>
                                <input
                                    value={user.email}
                                    onChange={(e) => handleChangeUser("email", e.target.value)} type="text"
                                    placeholder='Enter Email'
                                />
                                {errors && errors.email ? (
                                    <span type="text" className="error-red">
                                        {`* ${errors.email}`}
                                    </span>
                                ) : null}
                            </div>
                            <div className="input-box">
                                <span>Password*</span>
                                <input
                                    value={user.password}
                                    onChange={(e) => handleChangeUser("password", e.target.value)} type="password"
                                    placeholder='Enter Password'
                                />
                                {errors && errors.password ? (
                                    <span type="text" className="error-red">
                                        {`* ${errors.password}`}
                                    </span>
                                ) : null}
                            </div>
                            <div className="remember">
                                <div>
                                    <span type="text"><input type="checkbox" className='checkbox'/>Remember me</span>
                                </div>
                                <div className='forget-password'>
                                <Link to="/forgot-password"> Forgot password?</Link>
                                </div>
                            </div>
                            {errors && errors.message ? <span type="text">{errors.message}</span> : null}
                            <div className="input-box">
                                <button
                                    type="submit"
                                    disabled={isLoginLoading}
                                >
                                    {isLoginLoading ? (
                                        <CircularProgress color="secondary" size={30} />
                                    ) : 'Login'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

function mapStateToProps(state, props){
    return{
        isAuthenticated: getAuthenticatedState(state)
    }
}

export default connect(mapStateToProps)(Login);
