import React, {useState} from "react";

import DefaultModal from "components/DefaultModal/DefaultModal";
import actionDashboardTable from "../../content/ActionDashboardTable.json";

const ActionDashboardTable = () => {
    const [selectDeviceModal, setSelectDeviceModal] = useState(false);
    const openSelectDeviceModal = () => setSelectDeviceModal(true);
    const closeSelectDeviceModal = () => {
        setSelectDeviceModal(false);
    };
    return (
        <>
            <div className="add-device-section">
                <button type="button" onClick={openSelectDeviceModal}>SELECT DEVICE</button>
            </div>
            <div className='row reports-content-row dashboard-plant-table' style={{marginLeft: "0px"}}>
                <table className="table table-hover mobile-none">
                    <thead className='table-header-bg'>
                        <tr>
                            <th scope="col" className='table-heading'>ITEM</th>
                            <th scope="col" className='table-heading'>MODULE 1</th>
                            <th scope="col" className='table-heading'>MODULE 2</th>
                            <th scope='col' className='table-heading'>MODULE 3</th>
                        </tr>
                    </thead>
                    <tbody className='connectors-table-data'>
                        {actionDashboardTable?.map((item, idx) => (
                            <tr>
                                <td>{item.item}</td>
                                <td>{item.module1}</td>
                                <td>{item.module2}</td>
                                <td>{item.module3}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <DefaultModal
             show={selectDeviceModal}
             onHide={() => closeSelectDeviceModal(false)}
            />

            
        </>
    )
}

export default ActionDashboardTable;