import Header from '../Header/Header';
import PropTypes from 'prop-types';
import React from 'react';
import {useParams} from "react-router-dom";

const NavbarSidebarGlobal = ({children}) => {
    const params = useParams();
    const {id} = params;

    return (
        <>
            <Header/>
            <div className='container-fluid'>
                <div className=''>
                  
                        {children}
                    
                </div>
            </div>
        </>
    )
}

NavbarSidebarGlobal.propTypes = {
    children: PropTypes.element.isRequired,
};

export default NavbarSidebarGlobal;
