import './statusReport.css';

import {Chart, registerables} from 'chart.js';
import {
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import React, {useEffect, useState} from 'react'

import {CircularProgress} from "@mui/material";
import DateRangePicker from "react-bootstrap-daterangepicker";
import callApi from "../../util/apiCaller";
import moment from "moment";
import { borderRadius } from "styled-system";

Chart.register(...registerables);
const CustomTooltip = ({active, payload, label, value}) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label}`}</p>
                <p className="label">{`${value} : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const StatusReport = ({id, selectedDevice}) => {

    const [isLoading, setIsLoading] = useState(false);

    const [filters, setFilters] = useState({
        // end_date: moment().subtract(2, 'months').startOf("day").subtract({day: 1}),
        end_date: moment().startOf("day").subtract({day: 1}),
        start_date: moment().endOf("day").subtract({day: 15}),
        // start_date: moment().subtract(2, 'months').endOf("day").subtract({day: 15}),
    });
    const [data, setData] = useState([]);

   

    const fetchDeviceData = async (_id) => {
        try {
            setIsLoading(true);
            const res = await callApi(`deviceinputdatas/list?device_id=${_id}`, 'post', {
                filters: {
                    start_date: filters.start_date,
                    end_date: filters.end_date,
                },
                pageNum: 1,
                pageSize: 500
            });
            if (res && res.data) {
                const {list} = res.data;
                setData(list);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        } catch (err) {
            setIsLoading(false);
        }
    }
    useEffect(async () => {
        await fetchDeviceData(id);
    }, [id, filters])

    const handleKPIDateChange = (_, picker) => {
        const newFilters = {...filters};
        newFilters.start_date = picker.startDate;
        newFilters.end_date = picker.endDate;
        setFilters(newFilters);
    };

    const venuestart = filters.start_date.format('DD-MM-YYYY');
    const venueend = filters.end_date.format('DD-MM-YYYY');
    let kpidatelabel = `${venuestart} - ${venueend} `;
    if (venuestart === venueend) {
        kpidatelabel = venuestart;
    }

    // console.log("selected", selectedDevice);

    if (isLoading) {
        return (
            <div className="performance_wrap">
                <div>
                    <CircularProgress/>
                </div>
            </div>
        );
    }
    return (
        
            <div className='row'>
                <div className='col-lg-9 status-col'>
                    <div className='d-lg-flex justify-content-between'>
                        {/* Date-input */}
                        <div className='date-div'>
                            {/* <label for='input-start-date' className='date-input-label'>Start Date</label> */}
                            <DateRangePicker
                                initialSettings={{
                                    startDate: filters.start_date,
                                    endDate: filters.end_date,
                                    autoApply: true,
                                    maxDate: moment().endOf("day").subtract({day: 1})
                                }}
                                onApply={handleKPIDateChange}
                            >
                                <input
                                    value={kpidatelabel}
                                    className='date-input start-date' placeholder='Start Date'/>
                            </DateRangePicker>
                        </div>
                    </div>
                    {/* Date-input */}
                    <div>
                        <h2 className="time-series-graph">Connector Resistance</h2>
                    </div>

                    <div className='status-content'>
                        <div style={{marginTop: "20px"}}>
                            <div style={{marginTop: "90px", marginBottom: "40px"}}>
                                <ResponsiveContainer width="100%" aspect={3}>
                                    <LineChart data={data}>
                                        <XAxis dataKey="datetime"
                                               fontSize={"10px"}
                                               interval={'preserveStartEnd'}
                                        />
                                        <YAxis
                                            label={{ value: 'Resistance (m-ohm)' }}
                                        />
                                        <Legend/>
                                        <Tooltip content={<CustomTooltip
                                            value={'resistance'}
                                        />}/>
                                        <Line dataKey="resistance"
                                              stroke="orange" activeDot={{r: 8}}/>
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>

                    <h1 className="time-series-graph">Connector Temperature</h1>
                    <div className='status-content'>
                        <div style={{marginTop: "20px"}}>
                            <div style={{marginTop: "90px", marginBottom: "40px"}}>
                                <ResponsiveContainer width="100%" aspect={3}>
                                    <LineChart data={data}>
                                        <XAxis dataKey="datetime"
                                               fontSize={"10px"}
                                               interval={'preserveStartEnd'}
                                        />
                                        <YAxis
                                            label={{ value: 'Temperature (Celcius)' }}
                                        />
                                        <Legend/>
                                        <Tooltip content={<CustomTooltip
                                            value={'temperature'}
                                        />}/>
                                        <Line dataKey="surface_temperature"
                                              stroke="orange" activeDot={{r: 8}}/>
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>

                    <h1 className="time-series-graph">Dissipated Power</h1>
                    <div className='status-content'>
                        <div style={{marginTop: "20px"}}>
                            <div style={{marginTop: "90px", marginBottom: "40px"}}>
                                <ResponsiveContainer width="100%" aspect={3}>
                                    <LineChart data={data}>
                                        <XAxis dataKey="datetime"
                                               fontSize={"10px"}
                                               interval={'preserveStartEnd'}
                                        />
                                        <YAxis
                                            label={{ value: 'Power (W)' }}
                                        />
                                        <Legend/>
                                        <Tooltip content={<CustomTooltip
                                            value={'power'}
                                        />}/>
                                        <Line dataKey="power"
                                              stroke="orange" activeDot={{r: 8}}/>
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <div class="wrapper" style={{background: "#fff", height: "320px", top: "16%", borderRadius: "8px"}}>
                        <div class={`percent-${selectedDevice?.confidence_interval}`}>
                            <div class="speedometer play">
                                <div class="dial">
                                    <div class="bar">
                                        <div class="half-circle half-circle-bg"></div>
                                        <div class="half-circle half-circle-speed animated"></div>
                                        <div class="half-circle half-circle-cover"></div>
                                    </div>
                                    <div class="needle animated">
                                        <div class="inner">
                                            <div class="top"></div>
                                            <div class="bottom"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ "textAlign" : 'center' }}>{selectedDevice?.confidence_interval}</div>
                        <div className="btn-projection">Future Projections</div>
                    </div>

                </div>
            </div>
       
    )
}

export default StatusReport;
