import './performanceReports.css';

import {Link, useParams} from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import {CircularProgress} from '@mui/material';
import {Helmet} from "react-helmet";
import StatusReport from '../../components/StatusReportForm/StatusReport';
import connectorDevices from '../../content/connectorDevices.json';

const DeviceInfoPage = () => {
    const [selectedDevice, setSelectedDevice] = useState(null);
    const params = useParams();

    const {id} = params;

    useEffect(() => {
        if (id) {
            const tempDevice = connectorDevices.filter(item => item.id.toString() === id.toString());
            if (tempDevice) {
                setSelectedDevice(tempDevice[0]);
            }
        }
    }, [id])

    if (selectedDevice) {
        return (
            <div className='performance-row'>
                <div className='performance-heading-row'>
                    <div className='performance-heading-col'>
                        <div>
                            <p className='performance-heading-p'>Home / <Link to={"/"}>All Connectors
                            </Link> / <span>{selectedDevice?.connector_name}</span></p>
                            <h2 className='performance-heading-h2'>{selectedDevice?.connector_name}</h2>
                        </div>
                        <div>
                            <span className='today-action-report_dissabled'>
                                Refresh
                            </span>
                        </div>
                    </div>
                </div>
                <StatusReport
                    id={selectedDevice?.device_id}
                    selectedDevice={selectedDevice}
                />
            </div>
        );
    }
    return (
        <div className='performance-row'>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>IOT Device | SmartHelio</title>
            </Helmet>
            <div className="performance_wrap">
                <div>
                    <CircularProgress/>
                </div>
            </div>
        </div>
    );
}

export default DeviceInfoPage;
