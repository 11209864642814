import './ConnectorTable.css';

import { CircularProgress } from '@mui/material';
import ConnectorMiddleHeader from "../Connectors/ConnectorMiddleHeader";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import React from 'react';

const options = {
  chart: {
    height: 700,
    type: 'line',
    zoom: {
      enabled: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    curve: 'smooth',
    lineCap: 'butt',
    width: 2,
    dashArray: 0,
  },
  title: {
    text: 'Projected Loss Graph',
    align: 'left'
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  xaxis: {
    title: {
      text: 'Date'
    }
  },
  yaxis: {
    title: {
      text: 'Cumulative Projected Loss (in CHF)'
    },
  },
};


const ConnectorTable = ({ connectorDevices, setSelectedTab, selectedTab, faultMapper }) => {

  return (
    <>
      <div className='connecter-box'>
        <p>Connectors</p>
      </div>
      <div className='col-lg-12 reports-content'>
        <ConnectorMiddleHeader
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
        />
        <div className='row reports-content-row mt-4'>
          {connectorDevices.length ? (
            <div className='reports-content-box' style={{ textAlign: 'left' }}>
              <table className="table table-hover mobile-none">
                <thead className='table-header-bg'>
                <tr>
                  <th scope="col" className='table-heading'>Connector Name</th>
                  <th scope="col" className='table-heading'>Site Address
                  </th>
                  <th scope="col" className='table-heading'>{'Connector Resistance'}</th>
                  <th scope='col' className='table-heading'>
                    Connector Surface Temperature
                  </th>
                  <th scope='col' className='table-heading'>
                    Avg Ambient Temperature
                  </th>
                  <th scope='col' className='table-heading'>
                    Connector Fault
                  </th>
                  <th scope='col' className='table-heading'>
                    Age of Connectors
                  </th>
                </tr>
                </thead>
                <tbody className='connectors-table-data'>
                {connectorDevices?.filter(item => faultMapper[item?.fault].toString() === selectedTab).map((plant) => (
                  <tr
                    style={{ textAlign: 'center' }}
                    key={plant.connector_name}
                  >
                    <td>
                      <Link
                        to={`/device/${plant.id}/view`}
                        style={{textDecoration: "none",color: "#01579B"}}
                      >
                        {plant.connector_name}
                      </Link>
                    </td>
                    <td>
                      {plant.site_address}
                    </td>
                    <td>
                      {plant.resistance}
                    </td>
                    <td>
                      {plant.temperature}
                    </td>
                    <td>
                      {plant.ambient_temperature}
                    </td>
                    <td>
                      {plant.fault}
                    </td>
                    <td>
                      {plant.connector_age}
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <CircularProgress />
            </div>
          )}
        </div>

      </div>
      <div className='connecter-footer'>
        <p>Smarthelio 2022</p>
      </div>
    </>
  )
}

// ConnectorTable.propTypes = {
//   plants: PropTypes.arrayOf(Object).isRequired,
//   mainStats: PropTypes.instanceOf(Object).isRequired,
// };

export default ConnectorTable;
