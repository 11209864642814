import './forgotPage.css';

import React, {useState} from 'react';

import {Helmet} from "react-helmet";
import LoginImg from '../../assets/login-img.png';
import callApi from "util/apiCaller";
import {toast} from 'react-toastify';

const ForgotPasswordPage = () => {

    const [user, setUserData] = useState({
        email: '',
    });

    const notify = (data) => {
            toast(data)
    }

    const [errors, setErrors] = useState({});

    const handleChangeUser = (keyName, keyValue) => {
        const update = {...user};
        update[keyName] = keyValue;
        setUserData(update);
    }

    const handleValidation = (client) => {
        let isValid = true;
        const newErrors = {};
        if (!client.email) {
            isValid = false;
            newErrors.email = 'Please enter email';
        }
        return {
            isValid,
            errors: newErrors
        }
    };

    const handleForgotPasswordAPI = (creds, next) => {
        callApi(`auth/user/forgot-password`, 'post', {
            user: creds
        }).then(res => {
           
            return next(res);
        })
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        const check = handleValidation(user);
        if (check.isValid) {
            handleForgotPasswordAPI(user, (result) => {
                if (result.status === "Success") {
                    notify(result.data.message);
                    handleChangeUser("email", '');
                    // setTimeout(() => {
                    //     window.location = "/reset-password";
                    // }, 1000)
                    
                } else {
                    const error = {};
                    error.message = result.data.message;
                    setErrors(error);
                    notify(result.data.message);
                }
            });
        } else {
            setErrors(check.errors);
        }
    }

    return (
        <>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Forgot Page | SmartHelio</title>
            </Helmet>
            <section className='login-section'>
                <div className="img-box">
                    <img src={LoginImg} alt="LoginImg"/>
                </div>
                <div className="content-box">
                    <div className="form-box">
                        {/* <img src={Brand} className='brand-img' alt=""/> */}
                        <h2>Forgot your password?</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="input-box">
                                <span>Email*</span>
                                <input
                                    value={user.email}
                                    onChange={(e) => handleChangeUser("email", e.target.value)} type="text"
                                    placeholder='Enter Email'
                                />
                                {errors && errors.email ? <span>{errors.email}</span> : null}
                            </div>
                            
                            {errors && errors.message ? <span type="text">{errors.message}</span> : null}
                            <div className="input-box">
                                <input  type="submit" value='Forgot Password'/>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ForgotPasswordPage;
