import {
    SET_AUTH_STATE,
    SET_COMPANY,
    SET_IS_LOADING,
    SET_IS_LOGIN_LOADING,
    SET_PLANTS,
    SET_USER,
} from './AppActions';

import { NOT_INITIALIZED } from "constants/enums/authEnums";

const initialState = {
    authState: NOT_INITIALIZED,
    user: null,
    isLoginLoading: false,
    isLoading: false,
    message: "",
    refurl: "",
    messageType:"",
    company: null,
    plants: [],
}

const AppReducer = (state = initialState, action={}) =>{
    switch (action.type) {
        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            }
        case SET_IS_LOGIN_LOADING:
            return {
                ...state,
                isLoginLoading: action.isLoginLoading,
            }
        case SET_AUTH_STATE:
            return {
                ...state,
                authState: action.authState,
            }
        case SET_USER:
            return {
                ...state,
                user: action.user,
            }
        case SET_COMPANY:
            return {
                ...state,
                company: action.company,
            }
        case SET_PLANTS:
            return {
                ...state,
                plants: action.plants,
            }
        default:
            return state;
    }
}




export const getAuthenticatedState = state => state.app.authState;
export const getIsLoginLoading = state => state.app.isLoginLoading;
export const getPasswordProcessingState = state => state.app.isFetching;
export const getLoginMessage = state => state.app.message;
export const getLoginMessagetype = state => state.app.messagetype;
export const getRefUrl = state => state.app.refurl;
export const getCompany = state => state.app.company;
export const getUser = state => state.app.user;
export const getPlants = state => state.app.plants;

export default AppReducer;
