import React, {useEffect, useState} from 'react';

import {CircularProgress} from "@mui/material";
import {Helmet} from "react-helmet";
import UserTable from '../../components/UserTable/UserTable';
import callApi from "../../util/apiCaller";

const UserListPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);

  

    const fetchUsers = async () => {
        try {
            setIsLoading(true);
            const res = await callApi(`users/list`, 'post', {
                pageSize: 500
            });
            if (res && res.data) {
                const {list} = res.data;
                setUsers(list);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        } catch (err) {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        fetchUsers();
    }, []);

    return (
       
            <div className='row portfolio-row' style={{padding: "2rem"}}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Users | IOT Platform</title>
                </Helmet>
                {/* <div style={{marginTop: "100px"}}></div> */}
                {isLoading ?
                    <div className='row reports-content-row'>
                        <div>
                            <CircularProgress/>
                        </div>
                    </div> :
                    null
                }
                {!isLoading && users.length > 0 && (
                    <UserTable
                        users={users}
                    />
                )}
            </div>
    
    )
}

export default UserListPage;
