import styled from "styled-components";
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  system,
} from "styled-system";


const Box = styled.div`
  cursor: ${(props) => (props.onClick ? "pointer" : "auto")};

  ${space};
  ${color};
  ${typography};
  ${layout};
  ${flexbox};
  ${grid};
  ${background};
  ${border};
  ${position};
  ${shadow};

  ${system({
  cursor: {
    property: "cursor",
  },
  transform: {
    property: "transform",
  },
  whiteSpace: {
    property: "white-space",
  },
  textOverflow: {
    property: "text-overflow",
  },
})};
`;

export default Box;
